








import Requirement from '@/model/requirement';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Vue from 'vue';
import LinkedRequirement from '@/model/linked-requirement';

export default Vue.extend({
  name: 'AttachedLink',
  components: {
    LoadingIndicator,
  },
  props: {
    requirement: Object as () => Requirement,
    link: Object as () => LinkedRequirement,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    removeLinked() {
      this.loading = true;
      this.$store
        .dispatch('requirementStorage/removeLinkedListRequirement', {
          linkedRequirementId: this.link.id,
          requirement: this.requirement,
        })
        .finally(() => (this.loading = false));
    },
  },
});
