
















































import User from '@/model/user';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import TaskAssigneeRequest from '@/model/request/task-assignee-request';
import UserAvatar from '@/components/User/UserAvatar.vue';
import AutoComplete from '@/components/InputField/AutoComplete.vue';
import Task from '@/model/task';
import EmployeeList from '@/model/employee-list';
import Requirement from '@/model/requirement';

export default Vue.extend({
  name: 'AssigneeSelection',
  components: {
    UserAvatar,
    AutoComplete,
  },
  props: {
    category: String,
    requirement: Object as () => Requirement,
    task: Object as () => Task,
    confirmed: Boolean,
    hasInternalExternal: { type: Boolean, default: true },
    isEditing: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: this.task.assignee,
      focused: false,
    };
  },
  watch: {
    assignee() {
      this.selected = this.task.assignee;
    },
  },
  computed: {
    ...mapGetters({ assignableEmployees: 'userStorage/getAssignableEmployees' }),
    assignee(): User {
      return this.task.assignee;
    },
    assignableEployees(): User[] {
      return (
        this.assignableEmployees?.find((item: EmployeeList) => item.category === this.category)?.employees ??
        (this.task.assignee ? [this.task.assignee] : [])
      );
    },
    disabled(): boolean {
      return (
        !(this as any).isProcessOwner(this.requirement) ||
        this.confirmed ||
        (this as any).pastDeadline(this.requirement.deadline) ||
        this.isEditing ||
        (this as any).isAdminPage
      );
    },
  },
  methods: {
    isExternal(user: User): string {
      return user?.external ? 'K' : 'B';
    },
    shortenName(firstName: string, lastName: string) {
      return lastName.slice(0, 8) + ' ' + firstName.charAt(0) + '.';
    },
    loadAssignees() {
      this.focused = true;
      this.$store.dispatch('userStorage/getEmployees', this.category);
    },
    setAssignee(assigneeId: number | null) {
      this.$store
        .dispatch('requirementTaskStorage/setTaskAssignee', {
          taskAssigneeRequest: new TaskAssigneeRequest(assigneeId),
          taskId: this.task.id,
          requirement: this.requirement,
        })
        .then((data) => {
          this.selected = data;
        });
      (this.$refs.autocomplete as any).blur();
    },
    chooseAssignee(e: number) {
      this.setAssignee(e);
    },
    focusOut() {
      this.focused = false;
    },
    isDeleted() {
      return `${(this as any).fullName(this.selected)} ${
        this.selected.deleted ? `(${this.$t('dashboard.task.assignee.deleted')})` : ''
      }`;
    },
  },
});
