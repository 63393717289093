









































import Vue from 'vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import CommentRow from '@/components/Comment/CommentRow.vue';

export default Vue.extend({
  name: 'CommentBase',
  components: {
    LoadingIndicator,
    CommentRow,
  },
  props: {
    loading: { type: Boolean, default: false },
    comments: { type: Array as () => Comment[] },
    isEditing: { type: Boolean, default: false },
    restricted: { type: Boolean, default: false },
    isDocumentContainer: { type: Boolean, default: false },
  },
  data() {
    return {
      sendImage: '/images/send.svg',
      sending: false,
      submitted: false,
      comment: '',
    };
  },
  mounted() {
    this.updateScroll();
  },
  computed: {
    commentErrors(): string {
      return this.submitted && !(this.$v.comment as any).required
        ? this.$t('dashboard.detailed-view.comment.required').toString()
        : this.submitted && !(this.$v.comment as any).maxLength
        ? this.$t('dashboard.detailed-view.comment.max-length').toString()
        : '';
    },
  },
  validations: {
    comment: {
      required,
      maxLength: maxLength(10000),
    },
  },
  methods: {
    reset() {
      this.resetSending();
      this.updateScroll();
      this.submitted = false;
      this.comment = '';
    },
    resetSending() {
      this.sending = false;
    },
    send() {
      if (!this.isEditing) {
        this.submitted = true;
        this.$v.comment.$touch();
        if (this.$v.$anyError) {
          return;
        }
        this.sending = true;
        this.$emit('save', this.comment);
      }
    },
    updateScroll() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.comment as any) {
            (this.$refs.comment as any).scrollTop = (this.$refs.comment as any).scrollHeight;
          }
        }, 100);
      });
    },
  },
});
