
































import { mapGetters } from 'vuex';
import EmployeeList from '@/model/employee-list';
import User from '@/model/user';
import Requirement from '@/model/requirement';
import Vue from 'vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import ModifyLeaders from '@/components/Dashboard/DetailedView/Leader/ModifyLeaders.vue';
export default Vue.extend({
  name: 'Leaders',
  components: {
    UserAvatar,
    ModifyLeaders,
  },
  props: {
    requirement: Object as () => Requirement,
    restricted: { type: Boolean, default: false },
  },
  mounted() {
    this.$store.dispatch('userStorage/getEmployees', this.requirement.category);
  },
  computed: {
    ...mapGetters({
      assignableEmployees: 'userStorage/getAssignableEmployees',
      currentUser: 'currentUserStorage/getCurrentUser',
      isAdmin: 'currentUserStorage/isAdmin',
    }),
    assignableEployees(): User[] {
      return this.assignableEmployees?.find((item: EmployeeList) => item.category === this.requirement.category)
        ?.employees;
    },
  },
  methods: {
    getLeaders() {
      return this.assignableEployees?.filter((user: User) =>
        this.requirement?.processOwnerEmails?.includes(user.email),
      );
    },
    toggleLeader(user: User) {
      if (user.id !== this.currentUser.id || this.isAdmin) {
        this.$store.dispatch('requirementStorage/modifyProcessOwner', { user, requirement: this.requirement });
      }
    },
  },
});
