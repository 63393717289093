






import Vue from 'vue';
import ChangelogBase from '@/components/ChangelogBase.vue';
import Requirement from '@/model/requirement';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Changelog',
  components: {
    ChangelogBase,
  },
  props: {
    requirement: Object as () => Requirement,
  },
  mounted() {
    this.$store
      .dispatch('changelogStorage/getRequirementChangelogs', this.requirement.id)
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({ changelogs: 'changelogStorage/getRequirementChangelogs' }),
  },
  data() {
    return {
      loading: true,
    };
  },
});
