var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[_vm.isEditing ? 'edition-wrapper' : 'detail-wrapper']},[_c('div',{staticClass:"detail-container date-input"},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.addition')))]),(_vm.isEditing && _vm.isEditableRequirementField(_vm.requirement))?_c('DatePicker',{attrs:{"value":_vm.formatDate(_vm.editedDetails.additionDate),"requirement":_vm.requirement,"endDate":_vm.editedDetails.deadlineDate,"isEditing":_vm.isEditing,"isDisabled":_vm.saving,"addition":""},on:{"changeDate":_vm.onAdditionChange}}):_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.requirement.addition)))])],1),(
        !_vm.requirement.type ||
          (!_vm.isEditing && !_vm.requirement.technical) ||
          (_vm.isEditing && _vm.editedDetails.type && !_vm.editedDetails.type.technical)
      )?_c('div',{staticClass:"detail-container date-input"},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.deadline')))]),(_vm.isEditing && _vm.isEditableRequirementField(_vm.requirement))?[_c('DatePicker',{attrs:{"value":_vm.formatDate(_vm.editedDetails.deadlineDate),"requirement":_vm.requirement,"startDate":_vm.editedDetails.additionDate,"isEditing":_vm.isEditing,"isDisabled":_vm.saving},on:{"changeDate":_vm.onDeadlineChange}}),(_vm.deadlineError)?_c('span',{staticClass:"input-error-message"},[_vm._v(_vm._s(_vm.deadlineError))]):_vm._e()]:_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.requirement.deadline)))])],2):_vm._e(),_c('div',{staticClass:"detail-container"},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.prescribers')))]),_c('Prescribers',{attrs:{"requirement":_vm.requirement,"isDetailedView":"","selectable":_vm.isEditing && _vm.isEditableRequirementField(_vm.requirement),"isAddition":_vm.isEditing && _vm.isEditableRequirementField(_vm.requirement),"selected":_vm.editedDetails.prescribers},on:{"modifyPrescriber":_vm.onPresciberModification}}),(_vm.prescriberError)?_c('small',{staticClass:"input-error-message mt-1"},[_vm._v(_vm._s(_vm.prescriberError))]):_vm._e()],1),(!_vm.isContainer(_vm.requirement))?_c('div',{staticClass:"detail-container"},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.type')))]),(_vm.isEditing)?_c('TypeSelection',{attrs:{"hideDetails":"","loading":_vm.saving},on:{"modifyType":_vm.typeModification},model:{value:(_vm.editedDetails.type),callback:function ($$v) {_vm.$set(_vm.editedDetails, "type", $$v)},expression:"editedDetails.type"}}):_c('div',{staticClass:"d-flex row m-0 align-center"},[_c('TypeIcon',{attrs:{"type":_vm.requirement.type,"isDetailedView":""}}),_c('span',{staticClass:"px-4"},[_vm._v(" "+_vm._s(_vm.requirement.type.name)+" ")])],1)],1):_vm._e(),(
        (_vm.requirement.type && _vm.requirement.type.technical && !_vm.isEditing) ||
          (_vm.editedDetails.type && _vm.editedDetails.type.technical)
      )?_c('div',{staticClass:"detail-container"},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.document-store-visible')))]),_c('Checkbox',{staticClass:"document-store-checkbox",attrs:{"disabled":_vm.saving || !_vm.isEditing},model:{value:(_vm.editedDetails.documentStoreVisible),callback:function ($$v) {_vm.$set(_vm.editedDetails, "documentStoreVisible", $$v)},expression:"editedDetails.documentStoreVisible"}})],1):_vm._e(),(_vm.requirement.description || _vm.isEditing)?_c('div',{staticClass:"detail-container",class:{ 'pb-0': _vm.isEditing }},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.description')))]),(_vm.isEditing)?_c('v-textarea',{attrs:{"rows":"3","outlined":"","counter":"","maxlength":"10000","disabled":_vm.saving,"error-messages":_vm.descriptionError},model:{value:(_vm.editedDetails.description),callback:function ($$v) {_vm.$set(_vm.editedDetails, "description", $$v)},expression:"editedDetails.description"}}):_c('div',{staticClass:"description-container"},[_vm._v(_vm._s(_vm.requirement.description))])],1):_vm._e(),(
        (_vm.requirement.periodicOption && !_vm.isEditing) ||
          (_vm.isEditing && _vm.editedDetails.type && !_vm.editedDetails.type.technical)
      )?_c('div',{staticClass:"detail-container"},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.periodic')))]),(_vm.isEditing)?_c('Periodic',{attrs:{"selectedPeriod":_vm.editedDetails.periodicOption,"active":_vm.editedDetails.periodic,"submitted":_vm.submitted,"durationError":_vm.periodError},on:{"update:selectedPeriod":function($event){return _vm.$set(_vm.editedDetails, "periodicOption", $event)},"update:selected-period":function($event){return _vm.$set(_vm.editedDetails, "periodicOption", $event)},"update:active":function($event){return _vm.$set(_vm.editedDetails, "periodic", $event)}}}):_c('span',[_vm._v(_vm._s(_vm.requirementPeriod))])],1):_vm._e(),_c('div',{staticClass:"detail-container"},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.leaders')))]),_c('Leaders',{attrs:{"requirement":_vm.requirement,"restricted":_vm.restricted}})],1),(!_vm.isEditing)?_c('div',{staticClass:"detail-container"},[_c('div',{staticClass:"detail-container"},[_c('span',{staticClass:"detail-title"},[_vm._v(_vm._s(_vm.$t('dashboard.detailed-view.links')))]),_c('LinkList',{attrs:{"requirement":_vm.requirement,"restricted":_vm.restricted},on:{"click":_vm.openLinked}})],1)]):_vm._e()]),(_vm.isEditing)?_c('div',{staticClass:"detail-container edit-buttons w-100"},[_c('div',{staticClass:"d-flex row m-0"},[_c('div',{staticClass:"d-flex reset-container"},[(!_vm.saving)?_c('Button',{staticClass:"reset-button",attrs:{"text":_vm.$t('dashboard.detailed-view.reset-edit')},on:{"click":_vm.resetEdition}}):_vm._e()],1),_c('div',{staticClass:"w-25"},[(_vm.saving)?_c('LoadingIndicator'):_c('Button',{attrs:{"text":_vm.$t('dashboard.detailed-view.save')},on:{"click":_vm.saveEdited}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }