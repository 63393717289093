






















































































































import Vue from 'vue';
import FormBase from '@/components/Form/Form.vue';
import Prescribers from '@/components/Dashboard/Table/TableColumns/Prescribers.vue';
import TypeSelection from '@/components/Dashboard/RequirementAddition/TypeSelection.vue';
import RequirementType from '@/model/requirement-type';
import { Prescriber } from '@/model/enums/prescriber';
import DateInput from '@/components/Dashboard/RequirementAddition/DateInput.vue';
import FileInput from '@/components/InputField/FileInput.vue';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import RequirementRequest from '@/model/request/requirement-request';
import SelectedFile from '@/components/Dashboard/RequirementAddition/SelectedFile.vue';
import { mapGetters } from 'vuex';
import Periodic from '@/components/Dashboard/RequirementAddition/Periodic.vue';
import PeriodicOption from '@/model/periodic-option';
import { prescriberList, endBeforeStart, shortPeriod } from '@/validators/requirement';
import moment from 'moment';
import Requirement from '@/model/requirement';
import RequirementFilterRequest from '@/model/request/filter/requirement-filter-request';

export default Vue.extend({
  name: 'AdditionDialog',
  components: {
    FormBase,
    Prescribers,
    TypeSelection,
    DateInput,
    FileInput,
    SelectedFile,
    Periodic,
  },
  props: {
    category: String,
    mainRequirement: Number,
    isRequirement: { type: Boolean, default: false },
  },
  data() {
    return {
      submitted: false,
      loading: false,
      details: {
        title: '',
        addition: '',
        deadline: '',
        type: {} as RequirementType,
        description: '',
        prescribers: new Array<Prescriber>(),
        periodic: false,
        periodicOption: {} as PeriodicOption,
      },
      attachments: new Array<File>(),
    };
  },
  validations: {
    details: {
      title: {
        required,
        maxLength: maxLength(255),
      },
      deadline: {
        required: requiredIf(function(this: any) {
          return !this.details.type.technical;
        }),
        endBeforeStart(this: any) {
          return endBeforeStart(this.details.addition, this.details.deadline);
        },
      },
      type: {
        required: requiredIf(function(this: any) {
          return !this.isRequirement;
        }),
      },
      description: {
        maxLength: maxLength(10000),
      },
      prescribers: {
        prescriberList,
      },
      periodicOption: {
        shortPeriod(this: any) {
          return shortPeriod(
            this.details.periodicOption,
            this.submitted,
            this.details.addition,
            this.details.deadline,
            this.details.periodic,
          );
        },
      },
    },
  },
  mounted() {
    this.details.addition = (this as any).currentDate;
  },
  computed: {
    ...mapGetters({
      isEmployee: 'currentUserStorage/isEmployee',
      filterOptions: 'filterStorage/getRequirementFilterOptions',
    }),
    titleErrors(): string {
      return this.submitted && !(this.$v.details.title as any).required
        ? this.$t('dashboard.requirement-addition.title-required').toString()
        : this.submitted && !(this.$v.details.title as any).maxLength
        ? this.$t('dashboard.requirement-addition.title-max-length').toString()
        : '';
    },
    descriptionError(): string {
      return this.submitted && !(this.$v.details.description as any).maxLength
        ? this.$t('dashboard.requirement-addition.description-maxlength').toString()
        : '';
    },
    prescriberError(): string {
      return this.submitted && !(this.$v.details.prescribers as any).prescriberList
        ? this.$t('dashboard.requirement-addition.prescribers-required').toString()
        : '';
    },
    dateError(): string {
      return this.submitted && !(this.$v.details.deadline as any).endBeforeStart
        ? this.$t('dashboard.requirement-addition.start-past-end').toString()
        : '';
    },
    periodError(): string {
      return this.submitted && !(this.$v.details.periodicOption as any).shortPeriod
        ? this.$t('dashboard.requirement-addition.periodic.duration-short', {
            days: moment(this.details.deadline).diff(this.details.addition, 'days'),
          }).toString()
        : '';
    },
  },
  methods: {
    openDialog() {
      (this.$refs.additionDialog as any).open();
    },
    closeDialog() {
      (this.$refs.additionDialog as any).close();
    },
    resetDialog() {
      this.submitted = false;
      this.details.title = '';
      this.details.addition = (this as any).currentDate;
      this.details.deadline = '';
      this.details.type = {} as RequirementType;
      this.details.description = '';
      this.details.prescribers = [];
      this.details.periodic = false;
      this.details.periodicOption = {} as PeriodicOption;
      this.attachments = [];
      (this.$refs.additionDate as any).resetDate();
      if (this.$refs.deadline as any) {
        (this.$refs.deadline as any).resetDate();
      }
      if (this.$refs.typeSelection as any) {
        (this.$refs.typeSelection as any).reset();
      }
      if (this.$refs.periodic as any) {
        (this.$refs.periodic as any).reset();
      }
      (this.$refs.fileInput as any).addedFiles = [];
    },
    onDateChange(newDate: string, isAddition: boolean) {
      isAddition ? (this.details.addition = newDate) : (this.details.deadline = newDate);
    },
    onPresciberModification(prescriber: Prescriber) {
      (this as any).addOrRemoveItem(this.details.prescribers, prescriber);
    },
    onAttachmentModification(attachments: File[]) {
      this.attachments = this.attachments.concat(attachments);
    },
    onFileRemove(removedFile: File) {
      if (removedFile) {
        this.attachments = this.attachments.filter((file: File) => file.name !== removedFile.name);
      }
    },
    save() {
      this.submitted = true;
      this.$v.details.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const formData = new FormData();
      this.attachments.forEach((file: File) => {
        formData.append('file', file);
      });
      formData.append(
        'requirement',
        new Blob(
          [
            JSON.stringify(
              new RequirementRequest(
                null,
                this.details.title,
                this.details.addition ? this.details.addition : (this as any).currentDate,
                this.details.deadline,
                this.isRequirement ? null : this.details.type.id,
                false,
                this.details.description,
                this.details.prescribers,
                this.isRequirement || !this.details.periodic ? null : this.details.periodicOption,
                this.category,
                this.isRequirement,
                this.isRequirement ? undefined : this.mainRequirement,
              ),
            ),
          ],
          {
            type: 'application/json',
          },
        ),
      );

      formData.append(
        'filter',
        new Blob([JSON.stringify(new RequirementFilterRequest(this.category, this.filterOptions))], {
          type: 'application/json',
        }),
      );
      this.loading = true;
      this.$store
        .dispatch('requirementStorage/save', { formData, isRequirement: this.isRequirement })
        .then((data: Requirement) => {
          if (data) {
            this.closeDialog();
            this.$emit('reopenDetailedView', data);
          }
        })
        .finally(() => (this.loading = false));
    },
  },
});
