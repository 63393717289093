




import Vue from 'vue';
import RestoreButton from '@/components/Button/CircleButton.vue';
import Notification from '@/model/notification';
import AdminRequirement from '@/model/admin-requirement';

export default Vue.extend({
  name: 'RestoreRequirement',
  components: {
    RestoreButton,
  },
  props: {
    requirement: { type: Object as () => AdminRequirement },
    isDeleted: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    restoreRequirement() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t(
              this.requirement.single || this.requirement.mainRequirementId
                ? 'admin.requirement-settings.restore.subtask-confirmation'
                : 'admin.requirement-settings.restore.requirement-confirmation',
              {
                title: this.requirement.title,
              },
            ).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('adminRequirementStorage/restore', {
                requirementId: this.requirement.id,
                isDeleted: this.isDeleted,
              })
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
