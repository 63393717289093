































































import MainRequirementPanel from '@/components/ExpansionPanel/MainRequirementPanel.vue';
import AdminRequirementRow from '@/components/Admin/Requirements/RequirementRow.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import DetailedView from '@/components/Dashboard/DetailedView/DetailedViewDialog.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import AdminRequirement from '@/model/admin-requirement';
import Pageable from '@/model/page/pageable';
import Vue from 'vue';
import Requirement from '@/model/requirement';
import { RequirementTableType } from '@/model/enums/requirement-table-type';

export default Vue.extend({
  name: 'AdminRequirementTable',
  components: {
    MainRequirementPanel,
    AdminRequirementRow,
    InfiniteScroll,
    DetailedView,
    LoadingIndicator,
  },
  props: {
    title: { type: String },
    pageable: { type: Object as () => Pageable<AdminRequirement> },
    tableType: {
      validator: (value: string) =>
        [RequirementTableType.DELETED.toString(), RequirementTableType.INACTIVE.toString()].includes(value),
    },
    loading: { type: Boolean, default: false },
  },
  computed: {
    isDeleted(): boolean {
      return this.tableType === RequirementTableType.DELETED;
    },
  },
  data() {
    return {
      isMainDetailsOpen: false,
      isSubDetailsOpen: false,
      mainRequirementTitle: '',
      openedMainrequirementId: null as number | null,
      openedSubrequirementId: null as number | null,
    };
  },
  methods: {
    openMainDetailedView(requirementId: number) {
      this.openedMainrequirementId = requirementId;
      this.isMainDetailsOpen = true;
    },
    closeMainDetailedView() {
      this.isMainDetailsOpen = false;
      this.openedSubrequirementId = null;
      this.resetOpenedRequirement();
    },
    openSubDetailedView(requirementId: number, mainRequirementTitle: string) {
      this.openedSubrequirementId = requirementId;
      this.mainRequirementTitle = mainRequirementTitle;
      this.isSubDetailsOpen = true;
    },
    closeSubDetailedView() {
      this.isSubDetailsOpen = false;
      this.mainRequirementTitle = '';
      this.openedSubrequirementId = null;
      this.resetOpenedRequirement();
    },
    resetOpenedRequirement() {
      this.$store.commit('requirementStorage/setOpenedDetailedViewRequirement', {} as Requirement);
    },
  },
});
