




























































import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import TaskContainer from '@/components/Dashboard/DetailedView/Task/TaskContainer.vue';
import SubRequirementContainer from '@/components/Dashboard/DetailedView/Task/SubRequirementContainer.vue';
import DetailedTaskCard from '@/components/Dashboard/DetailedView/Task/DetailedTaskCard.vue';
import Requirement from '@/model/requirement';
import { mapGetters } from 'vuex';
import Task from '@/model/task';
import AdditionDialog from '@/components/Dashboard/RequirementAddition/AdditionDialog.vue';

export default Vue.extend({
  name: 'Tasks',
  components: {
    LoadingIndicator,
    TaskContainer,
    SubRequirementContainer,
    AdditionDialog,
    DetailedTaskCard,
  },
  props: {
    requirement: { type: Object as () => Requirement },
    task: { type: Object as () => Task },
    isEditing: { type: Boolean, default: false },
    restricted: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({ tasks: 'requirementTaskStorage/getTasks', isEmployee: 'currentUserStorage/isEmployee' }),
    subRequirementAdditionAllowed(): boolean {
      return (this as any).isProcessOwner(this.requirement) && !this.restricted && !this.requirement.confirmed;
    },
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      loading: false,
      detailedTask: this.task?.id ? this.task : ({} as Task),
    };
  },
  methods: {
    fetch() {
      if (!(this as any).isContainer(this.requirement)) {
        this.loading = true;
        this.$store
          .dispatch('requirementTaskStorage/getAllTasks', this.requirement.id)
          .finally(() => (this.loading = false));
      }
    },
    showDetailedTask(task: Task) {
      if (!this.isEditing) {
        this.detailedTask = task;
      }
    },
    showTaskSummary() {
      if (!this.isEditing) {
        this.detailedTask = {} as Task;
      }
    },
    showNext() {
      if (!this.isEditing) {
        this.detailedTask = this.findNextTask(this.detailedTask);
      }
    },
    findNextTask(task: Task): Task {
      return this.tasks.find((item: Task) => item.taskOrder === task.taskOrder + 1);
    },
    openSubrequirement(requirementId: number) {
      if (!this.isEditing) {
        this.$emit('openSubrequirement', requirementId);
      }
    },
    openAdditionDialog() {
      (this.$refs.additionDialog as any).openDialog();
    },
    reopenDetailedView(requirement: Requirement) {
      this.$emit('reopenDetailedView', requirement);
    },
  },
});
