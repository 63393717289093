




























































import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import FileInput from '@/components/InputField/FileInput.vue';
import UploadedAttachment from '@/components/Dashboard/DetailedView/Attachment/UploadedAttachment.vue';
import Requirement from '@/model/requirement';
import { mapGetters } from 'vuex';
import { uploadDocument } from '@/helper/methods';
import Document from '@/model/document';

export default Vue.extend({
  name: 'Attachments',
  components: {
    LoadingIndicator,
    FileInput,
    UploadedAttachment,
  },
  props: {
    requirement: Object as () => Requirement,
    isEditing: { type: Boolean, default: false },
    restricted: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      uploading: false,
    };
  },
  mounted() {
    this.$store.dispatch('documentStorage/getDocuments', this.requirement.id).finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({ documents: 'documentStorage/getDocuments', tasks: 'requirementTaskStorage/getTasks' }),
    requirementDocuments(): Document[] {
      return this.documents.filter((document: Document) => !document.taskId && !document.inherited);
    },
    inheritedDocuments(): Document[] {
      return this.documents.filter((document: Document) => document.inherited);
    },
  },
  methods: {
    upload(attachments: File[]) {
      const formData = uploadDocument(attachments);
      if (formData.has('file')) {
        this.uploading = true;
        this.$store
          .dispatch('documentStorage/uploadRequirementDocuments', { requirementId: this.requirement.id, formData })
          .finally(() => (this.uploading = false));
      }
    },
    filterTaskDocuments(taskDocuments: Document[]): Document[] {
      return taskDocuments.filter((document: Document) => !document.inherited);
    },
  },
});
