













import Vue from 'vue';
import { adminPages } from '@/constants/page/admin-pages';
import Menu from '@/model/menu';

export default Vue.extend({
  name: 'AdminPageButtons',
  props: {
    selectedPage: { type: Object as () => Menu },
  },
  model: {
    prop: 'selectedPage',
    event: 'change',
  },
  watch: {
    selectedPage() {
      this.selected = this.selectedPage;
    },
  },
  data() {
    return {
      adminPages: adminPages as Menu[],
      selected: this.selectedPage,
    };
  },
});
