



















import Vue from 'vue';
import Task from '@/model/task';
import Requirement from '@/model/requirement';
import TaskCompletitionRequest from '@/model/request/task-completition-request';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Notification from '@/model/notification';

export default Vue.extend({
  name: 'TaskCompletionIcon',
  components: {
    LoadingIndicator,
  },
  props: {
    task: Object as () => Task,
    requirement: Object as () => Requirement,
    isEditing: { type: Boolean, default: false },
    restricted: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isDisabled() {
      return this.task.finished
        ? this.requirement.confirmed || this.isEditing || this.restricted
        : !this.task.previousFinished ||
            this.requirement.confirmed ||
            !(this as any).isProcessOwner(this.requirement) ||
            this.isEditing ||
            this.restricted;
    },
  },
  methods: {
    setTaskCompletion(task: Task) {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('notification.task.' + (task.finished ? 'undo' : 'finish'), {
              task: this.$te('dashboard.task.' + task.task)
                ? this.$t('dashboard.task.' + task.task)
                    .toString()
                    .toUpperCase()
                : task.task.toUpperCase(),
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store
              .dispatch('requirementTaskStorage/manageTaskCompletition', {
                request: new TaskCompletitionRequest(!task.finished),
                requirement: this.requirement,
                task,
              })
              .finally(() => (this.loading = false));
          }
        });
    },
  },
});
