


























import LoadingIndicator from '@/components/LoadingIndicator.vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import Vue from 'vue';
import Document from '@/model/document';
import Clock from '@/../public/images/document-clock.svg?inline';
import Requirement from '@/model/requirement';
import DocumentExpiryRequest from '@/model/request/document-expiry-request';

export default Vue.extend({
  name: 'ExpiryDate',
  components: {
    LoadingIndicator,
    DatePicker,
    Clock,
  },
  props: {
    document: { type: Object as () => Document },
    requirement: { type: Object as () => Requirement },
  },
  computed: {
    formattedDate: {
      get(): string {
        return (this as any).formatDateShort(this.expiryDate);
      },
      set(value: string) {
        this.expiryDate = value;
      },
    },
  },
  watch: {
    expiryDate() {
      this.saving = true;
      this.$store
        .dispatch('documentStorage/setExpiryDate', {
          requirementId: this.requirement.id,
          documentId: this.document.id,
          request: new DocumentExpiryRequest(this.expiryDate),
        })
        .finally(() => (this.saving = false));
    },
  },
  data() {
    return {
      saving: false,
      expiryDate: this.document?.expiryDate?.toString() ?? '',
    };
  },
  methods: {
    openPicker() {
      (this.$refs.datePicker as any).open();
    },
  },
});
