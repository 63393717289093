





































import User from '@/model/user';
import UserDetails from '@/model/user-details';
import Vue from 'vue';

export default Vue.extend({
  name: 'UserAvatar',
  props: {
    user: Object as () => User | UserDetails,
    isAdmin: Boolean,
    isAssigneeList: Boolean,
    isUserList: Boolean,
    isComment: Boolean,
    isLeaderList: Boolean,
    isAuthorized: Boolean,
  },
  data() {
    return {
      hideDelete: true,
    };
  },
  computed: {
    randomColor(): string {
      const colors = [0x004e9b, 0x0095f4, 0x7fa6cd];
      return `#${colors[Math.floor(Math.random() * colors.length)].toString(16).padStart(6, '0')}`;
    },
  },
  methods: {
    toggleLeader() {
      this.$emit('toggleLeader');
    },
  },
});
