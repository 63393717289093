














































































































































import { periodicTerms } from '@/constants/periodic-terms';
import { periodicOptions } from '@/constants/periodic-options';
import Prescribers from '@/components/Dashboard/Table/TableColumns/Prescribers.vue';
import TypeIcon from '@/components/Dashboard/Table/TableColumns/Type/TypeIcon.vue';
import LinkList from '@/components/Dashboard/DetailedView/Link/LinkList.vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import TypeSelection from '@/components/Dashboard/RequirementAddition/TypeSelection.vue';
import Periodic from '@/components/Dashboard/RequirementAddition/Periodic.vue';
import Requirement from '@/model/requirement';
import Vue from 'vue';
import Leaders from '@/components/Dashboard/DetailedView/Leader/Leaders.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Button from '@/components/Button/Button.vue';
import RequirementType from '@/model/requirement-type';
import { Prescriber } from '@/model/enums/prescriber';
import moment from 'moment';
import { PICKER_DATE_FORMAT } from '@/constants/date-format';
import { maxLength, requiredIf } from 'vuelidate/lib/validators';
import { prescriberList, shortPeriod } from '@/validators/requirement';
import RequirementRequest from '@/model/request/requirement-request';

export default Vue.extend({
  name: 'Details',
  components: {
    Prescribers,
    TypeIcon,
    LinkList,
    Leaders,
    Checkbox,
    DatePicker,
    TypeSelection,
    Periodic,
    LoadingIndicator,
    Button,
  },
  props: {
    requirement: Object as () => Requirement,
    isEditing: { type: Boolean, default: false },
    editedTitle: String,
    restricted: { type: Boolean, default: false },
  },
  data() {
    return {
      periodicTerms,
      periodicOptions,
      editedDetails: {
        additionDate: this.requirement.addition,
        deadlineDate: this.requirement.deadline,
        prescribers: this.requirement?.prescribers ? [...this.requirement.prescribers] : [],
        type: this.requirement.type,
        description: this.requirement.description,
        periodic: !!this.requirement.periodicOption,
        periodicOption: this.requirement.periodicOption,
        documentStoreVisible: this.requirement.documentStoreVisible,
      },
      saving: false,
      submitted: false,
    };
  },
  validations: {
    editedDetails: {
      deadlineDate: {
        required: requiredIf(function(this: any) {
          return this.editedDetails.type && !this.editedDetails.type.technical;
        }),
      },
      description: {
        maxLength: maxLength(10000),
      },
      prescribers: {
        prescriberList,
      },
      periodicOption: {
        shortPeriod(this: any) {
          return shortPeriod(
            this.editedDetails.periodicOption,
            this.submitted,
            this.editedDetails.additionDate,
            this.editedDetails.deadlineDate,
            this.editedDetails.periodic,
          );
        },
      },
    },
  },
  computed: {
    requirementPeriod(): string {
      return (
        this.periodicOptions
          .find(
            (option) =>
              option.value.frequencyNumber === this.requirement?.periodicOption?.frequencyNumber &&
              option.value.term === this.requirement?.periodicOption?.term,
          )
          ?.title?.toString() ??
        `${this.requirement?.periodicOption?.frequencyNumber} ${
          this.periodicTerms.find((termOption) => termOption.value === this.requirement?.periodicOption?.term)?.title
        }`
      );
    },
    deadlineError(): string {
      return this.submitted && !(this.$v.editedDetails.deadlineDate as any).required
        ? this.$t('dashboard.requirement-addition.deadline-required').toString()
        : '';
    },
    descriptionError(): string {
      return this.submitted && !(this.$v.editedDetails.description as any).maxLength
        ? this.$t('dashboard.requirement-addition.description-maxlength').toString()
        : '';
    },
    prescriberError(): string {
      return this.submitted && !(this.$v.editedDetails.prescribers as any).prescriberList
        ? this.$t('dashboard.requirement-addition.prescribers-required').toString()
        : '';
    },
    periodError(): string {
      return this.submitted && !(this.$v.editedDetails.periodicOption as any).shortPeriod
        ? this.$t('dashboard.requirement-addition.periodic.duration-short', {
            days: moment(this.editedDetails.deadlineDate).diff(this.editedDetails.additionDate, 'days'),
          }).toString()
        : '';
    },
  },
  methods: {
    openLinked(linkedRequirementId: number) {
      if (!this.restricted) {
        this.$emit('openLinked', linkedRequirementId);
      }
    },
    openEdition() {
      this.editedDetails.additionDate = this.requirement.addition;
      this.editedDetails.deadlineDate = this.requirement.deadline;
      this.editedDetails.prescribers = this.requirement?.prescribers ? [...this.requirement.prescribers] : [];
      this.editedDetails.type = this.requirement.type;
      this.editedDetails.description = this.requirement.description;
      this.editedDetails.periodic = !!this.requirement.periodicOption;
      this.editedDetails.periodicOption = this.requirement.periodicOption;
      this.editedDetails.documentStoreVisible = this.requirement.documentStoreVisible;
    },
    resetEdition() {
      this.$emit('resetEdition');
      this.submitted = false;
    },
    saveEdited() {
      this.submitted = true;
      this.$v.editedDetails.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const modificationRequest = new RequirementRequest(
        this.requirement.id,
        this.editedTitle,
        moment(this.editedDetails.additionDate).format(PICKER_DATE_FORMAT),
        this.editedDetails.deadlineDate ? moment(this.editedDetails.deadlineDate).format(PICKER_DATE_FORMAT) : '',
        this.editedDetails.type?.id ?? null,
        this.editedDetails.type?.technical ? this.editedDetails.documentStoreVisible : true,
        this.editedDetails.description,
        this.editedDetails?.prescribers ?? [],
        this.editedDetails.periodic && !this.editedDetails?.type.technical ? this.editedDetails.periodicOption : null,
        this.requirement.category,
        !this.requirement.type?.id,
      );
      this.saving = true;

      this.$store
        .dispatch('requirementStorage/saveEditedDetails', {
          requirement: this.requirement,
          modificationRequest,
        })
        .then(() => (this.saving = false))
        .finally(() => {
          this.$emit('getTasks');
          this.$emit('closeEdition');
          this.submitted = false;
        });
    },
    onAdditionChange(newDate: Date) {
      this.onDateChange(newDate, true);
    },
    onDeadlineChange(newDate: Date) {
      this.onDateChange(newDate, false);
    },
    onDateChange(newDate: Date, isAddition: boolean) {
      isAddition ? (this.editedDetails.additionDate = newDate) : (this.editedDetails.deadlineDate = newDate);
    },
    typeModification(newType: RequirementType) {
      this.editedDetails.type = newType;
    },
    onPresciberModification(prescriber: Prescriber) {
      (this as any).addOrRemoveItem(this.editedDetails?.prescribers ?? [], prescriber);
    },
  },
});
