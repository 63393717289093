




















import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Requirement from '@/model/requirement';
import Chnagelog from '@/model/changelog';
import { ChangeType } from '@/model/enums/change-type';
import { periodicTerms } from '@/constants/periodic-terms';
import SortedChangelog from '@/model/sorted-changelog';

export default Vue.extend({
  name: 'ChangelogBase',
  components: {
    LoadingIndicator,
  },
  props: {
    loading: { type: Boolean, default: false },
    changelogs: { type: Object as () => SortedChangelog },
    requirement: { type: Object as () => Requirement },
  },
  data() {
    return {
      periodicTerms,
    };
  },
  methods: {
    getLogMessage(changelog: Chnagelog): string {
      let logmessage = '';
      switch (changelog.changelogType) {
        case ChangeType.REQUIREMENT_ADDITION:
          logmessage = this.$t('dashboard.detailed-view.changelog.requirement-addition', {
            name: changelog.user,
          }).toString();
          break;
        case ChangeType.SUBTASK_ADDITION:
          logmessage = this.$t('dashboard.detailed-view.changelog.subtask-addition', {
            name: changelog.user,
          }).toString();
          break;
        case ChangeType.TASK_CONFIRMATION:
          logmessage = this.$t('dashboard.detailed-view.changelog.task-confirmation', {
            name: changelog.user,
            task: changelog?.changedTask.task,
          }).toString();
          break;
        case ChangeType.TASK_CONFIRMATION_WITHDRAW:
          logmessage = this.$t('dashboard.detailed-view.changelog.task-confirmation-withdraw', {
            name: changelog.user,
            task: changelog?.changedTask.task,
          }).toString();
          break;
        case ChangeType.TASK_DEADLINE_SET:
          logmessage = this.$t('dashboard.detailed-view.changelog.task-deadline-set', {
            name: changelog.user,
            task: changelog?.changedTask?.task,
            date: (this as any).formatDate(changelog.newDate),
          }).toString();
          break;
        case ChangeType.TASK_DEADLINE_DELETE:
          logmessage = this.$t('dashboard.detailed-view.changelog.task-deadline-delete', {
            name: changelog.user,
            task: changelog?.changedTask.task,
          }).toString();
          break;
        case ChangeType.TASK_ASSIGNEE_SET:
          logmessage = this.$t('dashboard.detailed-view.changelog.task-assignee-set', {
            name: changelog.user,
            assigneeName: (this as any).fullName(changelog?.changedUser),
            task: changelog?.changedTask.task,
          }).toString();
          break;
        case ChangeType.TASK_ASSIGNEE_DELETE:
          logmessage = this.$t('dashboard.detailed-view.changelog.task-assignee-delete', {
            name: changelog.user,
            task: changelog?.changedTask.task,
          }).toString();
          break;
        case ChangeType.SUBREQUIREMENT_CONFIRMATION:
          logmessage = this.$t('dashboard.detailed-view.changelog.subrequirement-confirmation', {
            name: changelog.user,
            requirement: changelog?.changedSubRequirement?.title,
          }).toString();
          break;
        case ChangeType.SUBREQUIREMENT_CONFIRMATION_WITHDRAW:
          logmessage = this.$t('dashboard.detailed-view.changelog.subrequirement-confirmation-withdraw', {
            name: changelog.user,
            requirement: changelog?.changedSubRequirement?.title,
          }).toString();
          break;
        case ChangeType.MAINREQUIREMENT_CONFIRMATION:
          logmessage = this.$t('dashboard.detailed-view.changelog.mainrequirement-confirmation', {
            name: changelog.user,
            requirement: changelog?.requirement.title,
          }).toString();
          break;
        case ChangeType.MAINREQUIREMENT_CONFIRMATION_WITHDRAWN:
          logmessage = this.$t('dashboard.detailed-view.changelog.mainrequirement-confirmation-withdraw', {
            name: changelog.user,
            requirement: changelog?.requirement.title,
          }).toString();
          break;
        case ChangeType.NEW_SUBTASK:
          logmessage = this.$t('dashboard.detailed-view.changelog.new-subtask', {
            name: changelog.user,
            requirement: changelog?.changedSubRequirement?.title,
          }).toString();
          break;
        case ChangeType.SUBREQUIREMENT_REMOVED:
          logmessage = this.$t('dashboard.detailed-view.changelog.subrequirement-removed', {
            name: changelog.user,
            requirement: changelog?.changedSubRequirement?.title,
          }).toString();
          break;
        case ChangeType.EDIT_TITLE:
          logmessage = this.$t('dashboard.detailed-view.changelog.edit-title', {
            name: changelog.user,
            previousTitle: changelog.prevText,
            currentTitle: changelog.newText,
          }).toString();
          break;
        case ChangeType.EDIT_ADDITION_DATE:
          logmessage = this.$t('dashboard.detailed-view.changelog.edit-subtask-addition-date', {
            name: changelog.user,
            previousDate: (this as any).formatDate(changelog.prevDate),
            currentDate: (this as any).formatDate(changelog.newDate),
          }).toString();
          break;
        case ChangeType.EDIT_DEADLINE_DATE:
          logmessage = this.$t('dashboard.detailed-view.changelog.edit-subtask-deadline-date', {
            name: changelog.user,
            previousDate: (this as any).formatDate(changelog.prevDate),
            currentDate: (this as any).formatDate(changelog.newDate),
          }).toString();
          break;
        case ChangeType.EDIT_TYPE:
          logmessage = this.$t('dashboard.detailed-view.changelog.edit-type', {
            name: changelog.user,
            previousType: changelog?.prevType.name,
            currentType: changelog?.newType.name,
          }).toString();
          break;
        case ChangeType.EDIT_PRESCRIBERS:
          logmessage = this.$t('dashboard.detailed-view.changelog.edit-prescribers', {
            name: changelog.user,
          }).toString();
          break;
        case ChangeType.EDIT_DESCRIPTION:
          logmessage =
            changelog?.newText && changelog?.prevText
              ? this.$t(
                  this.requirement.type
                    ? 'dashboard.detailed-view.changelog.edit-subtask-description'
                    : 'dashboard.detailed-view.changelog.edit-requirement-description',
                  {
                    name: changelog.user,
                    previousDescription: changelog?.prevText,
                    newDescription: changelog?.newText,
                  },
                ).toString()
              : changelog?.newText
              ? this.$t(
                  this.requirement.type
                    ? 'dashboard.detailed-view.changelog.add-subtask-description'
                    : 'dashboard.detailed-view.changelog.add-requirement-description',
                  {
                    name: changelog.user,
                    newDescription: changelog?.newText,
                  },
                ).toString()
              : this.$t(
                  this.requirement.type
                    ? 'dashboard.detailed-view.changelog.delete-subtask-description'
                    : 'dashboard.detailed-view.changelog.delete-requirement-description',
                  {
                    name: changelog.user,
                  },
                ).toString();
          break;
        case ChangeType.EDIT_PERIODICITY:
          logmessage = this.$t('dashboard.detailed-view.changelog.edit-periodicity', {
            name: changelog.user,
            previousFrequency: changelog?.prevNumber ?? '',
            previousTerm:
              this.periodicTerms.find((term) => term.value === changelog.prevText)?.title ??
              this.$t('dashboard.detailed-view.changelog.periodic.not-set'),
            currentFrequency: changelog?.newNumber ?? '',
            currentTerm:
              this.periodicTerms.find((term) => term.value === changelog.newText)?.title ??
              this.$t('dashboard.detailed-view.changelog.periodic.not-set'),
          }).toString();
          break;
        case ChangeType.NEW_OWNER_ADDED:
          logmessage = this.$t('dashboard.detailed-view.changelog.new-owner-added', {
            ownerName: (this as any).fullName(changelog?.changedUser),
            name: changelog.user,
          }).toString();
          break;
        case ChangeType.OWNER_REMOVED:
          logmessage = this.$t('dashboard.detailed-view.changelog.owner-removed', {
            ownerName: (this as any).fullName(changelog?.changedUser),
            name: changelog.user,
          }).toString();
          break;
        case ChangeType.NEW_DOCUMENT_ADDED:
          logmessage = this.$t('dashboard.detailed-view.changelog.new-document-added', {
            name: changelog.user,
            fileName: changelog?.changedDocument?.file.originalName,
          }).toString();
          break;
        case ChangeType.NEW_TASK_DOCUMENT_ADDED:
          logmessage = this.$t('dashboard.detailed-view.changelog.new-task-document-added', {
            name: changelog.user,
            fileName: changelog?.changedDocument?.file.originalName,
            task: changelog?.changedTask?.task,
          }).toString();
          break;
        case ChangeType.DOCUMENT_REMOVED:
          logmessage = this.$t('dashboard.detailed-view.changelog.document-removed', {
            name: changelog.user,
            fileName: changelog?.changedDocument?.file.originalName,
          }).toString();
          break;
        case ChangeType.TASK_DOCUMENT_REMOVED:
          logmessage = this.$t('dashboard.detailed-view.changelog.task-document-removed', {
            name: changelog.user,
            fileName: changelog?.changedDocument?.file.originalName,
            task: changelog?.changedTask?.task,
          }).toString();
          break;
        case ChangeType.REQUIREMENT_CONFIRMATION:
          logmessage = this.$t('dashboard.detailed-view.changelog.requirement-confirmation', {
            name: changelog.user,
          }).toString();
          break;
        case ChangeType.REQUIREMENT_CONFIRMATION_WITHDRAW:
          logmessage = this.$t(
            this.requirement.type
              ? 'dashboard.detailed-view.changelog.requirement-confirmation-withdraw'
              : 'dashboard.detailed-view.changelog.subtask-confirmation-withdraw',
            {
              name: changelog.user,
            },
          ).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_TITLE_CHANGE:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-title-change', {
            name: changelog.user,
            title: changelog.newText,
          }).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_VISIBLE:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-visible', {
            name: changelog.user,
            documentName: changelog.changedDocument?.file?.originalName,
          }).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_NOT_VISIBLE:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-not-visible', {
            name: changelog.user,
            documentName: changelog.changedDocument?.file?.originalName,
          }).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_PERSON_ADDITION:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-person-addition', {
            name: changelog.user,
            person: (this as any).fullName(changelog.person),
          }).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_PERSON_REMOVAL:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-person-removal', {
            name: changelog.user,
            person: (this as any).fullName(changelog.person),
          }).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_PLACE_ADDITION:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-place-addition', {
            name: changelog.user,
            place: changelog.place?.name,
          }).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_PLACE_REMOVAL:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-place-removal', {
            name: changelog.user,
            place: changelog.place?.name,
          }).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_TOOL_ADDITION:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-tool-addition', {
            name: changelog.user,
            tool: changelog.tool?.name,
          }).toString();
          break;
        case ChangeType.DOCUMENT_CONTAINER_TOOL_REMOVAL:
          logmessage = this.$t('documents.detailed-view.changelog.document-container-tool-removal', {
            name: changelog.user,
            tool: changelog.tool?.name,
          }).toString();
          break;
      }
      return logmessage;
    },
  },
});
