













import Vue from 'vue';
import CommentBase from '@/components/Comment/CommentBase.vue';
import { mapGetters } from 'vuex';
import Requirement from '@/model/requirement';
import CommentRequest from '@/model/request/comment-request';

export default Vue.extend({
  name: 'Comments',
  components: {
    CommentBase,
  },
  props: {
    requirement: { type: Object as () => Requirement },
    isEditing: { type: Boolean, default: false },
    restricted: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$store
      .dispatch('commentStorage/getRequirementComments', this.requirement.id)
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({ comments: 'commentStorage/getRequirementComments' }),
  },
  methods: {
    sendComment(comment: string) {
      this.$store
        .dispatch('commentStorage/sendRequirementComment', {
          requirementId: this.requirement.id,
          commentRequest: new CommentRequest(comment),
        })
        .then((data) => {
          if (data) {
            this.reset();
          }
        })
        .finally(() => (this.$refs.commentInput as any).resetSending());
    },
    reset() {
      (this.$refs.commentInput as any).reset();
    },
  },
});
