












































import Notification from '@/model/notification';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import ExpiryDate from '@/components/Dashboard/DetailedView/Attachment/ExpiryDate.vue';
import Vue from 'vue';
import Requirement from '@/model/requirement';
import Document from '@/model/document';
import Task from '@/model/task';

export default Vue.extend({
  name: 'UploadedAttachment',
  components: {
    LoadingIndicator,
    Checkbox,
    ExpiryDate,
  },
  props: {
    requirement: Object as () => Requirement,
    task: { type: Object as () => Task, default: null },
    document: Object as () => Document,
    uploading: { type: Boolean, default: false },
    isTaskSite: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    restricted: { type: Boolean, default: false },
  },
  computed: {
    typeIcon(): string {
      return this.document.file.type.startsWith('image/')
        ? '/images/image.svg'
        : this.document.file.type.startsWith('video/')
        ? '/images/video.png'
        : this.document.file.type.startsWith('audio/')
        ? '/images/audio.png'
        : '/images/document.svg';
    },
  },
  data() {
    return {
      deleteIcon: '/images/detailed-view-close.svg',
      deleting: false,
    };
  },
  methods: {
    toggle(document: Document) {
      this.$store.dispatch('documentStorage/setInherited', {
        documentId: document.id,
        requirementId: this.requirement.id,
        taskId: document.taskId ?? '',
      });
    },
    removeDocument() {
      if (!this.uploading && !this.isEditing && !this.restricted) {
        this.$store
          .dispatch(
            'showConfirmNotification',
            new Notification(
              this.$t('dashboard.detailed-view.attachment.sure-to-delete', {
                fileName: this.document.file.originalName,
              }).toString(),
            ),
          )
          .then((confirmed) => {
            if (confirmed) {
              this.deleting = true;
              this.$store
                .dispatch('documentStorage/deleteDocument', {
                  requirementId: this.requirement.id,
                  documentId: this.document.id,
                  taskId: this.task?.id ?? '',
                })
                .finally(() => (this.deleting = false));
            }
          });
      }
    },
  },
});
