






















import Vue from 'vue';
import RestoreRequirement from '@/components/Admin/Requirements/RestoreRequirement.vue';
import InfoButton from '@/components/Button/CircleButton.vue';
import AdminRequirement from '@/model/admin-requirement';

export default Vue.extend({
  name: 'AdminRequirementRow',
  components: {
    RestoreRequirement,
    InfoButton,
  },
  props: {
    requirement: { type: Object as () => AdminRequirement },
    isSubrequirement: { type: Boolean, default: false },
    isExpandable: { type: Boolean, default: false },
    isDeleted: { type: Boolean, default: false },
  },
});
