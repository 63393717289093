export enum ChangeType {
  REQUIREMENT_ADDITION = 'REQUIREMENT_ADDITION',
  SUBTASK_ADDITION = 'SUBTASK_ADDITION',
  TASK_CONFIRMATION = 'TASK_CONFIRMATION',
  TASK_CONFIRMATION_WITHDRAW = 'TASK_CONFIRMATION_WITHDRAW',
  TASK_DEADLINE_SET = 'TASK_DEADLINE_SET',
  TASK_DEADLINE_DELETE = 'TASK_DEADLINE_DELETE',
  TASK_ASSIGNEE_SET = 'TASK_ASSIGNEE_SET',
  TASK_ASSIGNEE_DELETE = 'TASK_ASSIGNEE_DELETE',
  SUBREQUIREMENT_CONFIRMATION = 'SUBREQUIREMENT_CONFIRMATION',
  SUBREQUIREMENT_CONFIRMATION_WITHDRAW = 'SUBREQUIREMENT_CONFIRMATION_WITHDRAW',
  MAINREQUIREMENT_CONFIRMATION = 'MAINREQUIREMENT_CONFIRMATION',
  MAINREQUIREMENT_CONFIRMATION_WITHDRAWN = 'MAINREQUIREMENT_CONFIRMATION_WITHDRAWN',
  NEW_SUBTASK = 'NEW_SUBTASK',
  SUBREQUIREMENT_REMOVED = 'SUBREQUIREMENT_REMOVED',
  EDIT_TITLE = 'EDIT_TITLE',
  EDIT_ADDITION_DATE = 'EDIT_ADDITION_DATE',
  EDIT_DEADLINE_DATE = 'EDIT_DEADLINE_DATE',
  EDIT_TYPE = 'EDIT_TYPE',
  EDIT_PRESCRIBERS = 'EDIT_PRESCRIBERS',
  EDIT_DESCRIPTION = 'EDIT_DESCRIPTION',
  EDIT_PERIODICITY = 'EDIT_PERIODICITY',
  NEW_OWNER_ADDED = 'NEW_OWNER_ADDED',
  OWNER_REMOVED = 'OWNER_REMOVED',
  NEW_DOCUMENT_ADDED = 'NEW_DOCUMENT_ADDED',
  NEW_TASK_DOCUMENT_ADDED = 'NEW_TASK_DOCUMENT_ADDED',
  DOCUMENT_REMOVED = 'DOCUMENT_REMOVED',
  TASK_DOCUMENT_REMOVED = 'TASK_DOCUMENT_REMOVED',
  REQUIREMENT_CONFIRMATION = 'REQUIREMENT_CONFIRMATION',
  REQUIREMENT_CONFIRMATION_WITHDRAW = 'REQUIREMENT_CONFIRMATION_WITHDRAW',
  DOCUMENT_CONTAINER_TITLE_CHANGE = 'DOCUMENT_CONTAINER_TITLE_CHANGE',
  DOCUMENT_CONTAINER_VISIBLE = 'DOCUMENT_CONTAINER_VISIBLE',
  DOCUMENT_CONTAINER_NOT_VISIBLE = 'DOCUMENT_CONTAINER_NOT_VISIBLE',
  DOCUMENT_CONTAINER_PERSON_ADDITION = 'DOCUMENT_CONTAINER_PERSON_ADDITION',
  DOCUMENT_CONTAINER_PERSON_REMOVAL = 'DOCUMENT_CONTAINER_PERSON_REMOVAL',
  DOCUMENT_CONTAINER_PLACE_ADDITION = 'DOCUMENT_CONTAINER_PLACE_ADDITION',
  DOCUMENT_CONTAINER_PLACE_REMOVAL = 'DOCUMENT_CONTAINER_PLACE_REMOVAL',
  DOCUMENT_CONTAINER_TOOL_ADDITION = 'DOCUMENT_CONTAINER_TOOL_ADDITION',
  DOCUMENT_CONTAINER_TOOL_REMOVAL = 'DOCUMENT_CONTAINER_TOOL_REMOVAL',
}
