




































import Requirement from '@/model/requirement';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import AttachedLink from '@/components/Dashboard/DetailedView/Link/AttachedLink.vue';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'LinkList',
  components: {
    LoadingIndicator,
    AttachedLink,
  },
  props: {
    requirement: Object as () => Requirement,
    restricted: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({ requirements: 'requirementStorage/getLinkedListRequirements' }),
  },
  data() {
    return {
      adding: false,
    };
  },
  methods: {
    fetch() {
      this.$store.dispatch('requirementStorage/getLinkedListRequirements', this.requirement);
    },
    addItem(e: number) {
      this.adding = true;
      this.$store
        .dispatch('requirementStorage/addLinkedListRequirement', {
          linkedRequirementId: e,
          requirement: this.requirement,
        })
        .finally(() => {
          this.adding = false;
        });
    },
    filter(item: Requirement, queryText: string): boolean {
      return item.title.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
  },
});
