









import Vue from 'vue';
import Requirement from '@/model/requirement';

export default Vue.extend({
  name: 'SubRequirementContainer',
  props: {
    requirement: Object as () => Requirement,
    isEditing: { type: Boolean, default: false },
  },
});
