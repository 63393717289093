










































































import DetailedViewBase from '@/components/DetailedViewPopup/DetailedViewBase.vue';
import Requirement from '@/model/requirement';
import Status from '@/components/Dashboard/Table/TableColumns/Status.vue';
import Navigation from '@/components/Dashboard/DetailedView/Navigation.vue';
import Details from '@/components/Dashboard/DetailedView/Details.vue';
import Attachments from '@/components/Dashboard/DetailedView/Attachments.vue';
import Changelog from '@/components/Dashboard/DetailedView/Changelog.vue';
import Comments from '@/components/Dashboard/DetailedView/Comments.vue';
import Tasks from '@/components/Dashboard/DetailedView/Tasks.vue';
import ActionButton from '@/components/DetailedViewPopup/ActionButton.vue';
import Notification from '@/model/notification';
import Vue from 'vue';
import { findModifiedRequirement } from '@/helper/methods';
import { mapGetters } from 'vuex';
import { menus } from '@/constants/menu/dashboard-detailed-view-menus';
import Task from '@/model/task';

export default Vue.extend({
  name: 'DetailedViewDialog',
  components: {
    DetailedViewBase,
    Status,
    Navigation,
    Details,
    Attachments,
    Comments,
    Changelog,
    Tasks,
    ActionButton,
  },
  props: {
    requirementId: { type: Number },
    opened: { type: Boolean, default: false },
    openedTask: { type: Object as () => Task },
    tableType: { type: String, default: null },
    restricted: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      editIcon: '/images/edit.svg',
      deleteIcon: '/images/bin.svg',
      activeComponent: 'Tasks',
      isEditing: false,
      editedTitle: '',
      menus,
    };
  },
  mounted() {
    this.$store
      .dispatch('requirementStorage/getById', {
        requirementId: this.requirementId,
        tableType: this.tableType,
      })
      .then(() => (this.editedTitle = this.openedRequirement.title))
      .finally(() => (this.loading = false));
  },
  computed: {
    isSaving(): boolean {
      return (this.$refs.details as any).saving;
    },
    ...mapGetters({
      isAdmin: 'currentUserStorage/isAdmin',
      requirements: 'requirementStorage/getRequirements',
      openedRequirement: 'requirementStorage/getOpenedDetailedViewRequirement',
    }),
  },
  methods: {
    closeDialog() {
      this.$emit('close');
      this.activeComponent = 'Tasks';
      this.resetCommentSite();
    },
    deleteRequirement() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t(
              this.openedRequirement.type
                ? 'notification.confirmation.sure-to-delete-subtask'
                : 'notification.confirmation.sure-to-delete-requirement',
              {
                requirement: this.openedRequirement.title,
              },
            ).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.$store.dispatch('requirementStorage/delete', this.openedRequirement).then(() => {
              this.closeDialog();
            });
          }
        });
    },
    deactivateRequirement() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t(
              this.openedRequirement.type
                ? 'notification.confirmation.sure-to-deactivate-subtask'
                : 'notification.confirmation.sure-to-deactivate-requirement',
              {
                requirement: this.openedRequirement.title,
              },
            ).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.$store.dispatch('requirementStorage/deactivate', this.openedRequirement).then(() => {
              this.closeDialog();
            });
          }
        });
    },
    onComponentChange(newComponent: string) {
      this.resetCommentSite();
      this.activeComponent = newComponent;
    },
    resetCommentSite() {
      if (this.activeComponent === 'Comments') {
        (this.$refs.sideMenu as any).reset();
      }
    },
    loadTasks() {
      // TODO: Use type instead of any
      if (this.activeComponent === 'Tasks') {
        (this.$refs.sideMenu as any).fetch();
      }
    },
    loadChangelog() {
      if (this.activeComponent === 'Changelog') {
        (this.$refs.sideMenu as any).fetch();
      }
    },
    openSubrequirement(openedRequirementId: number) {
      this.$emit('openSubrequirement', openedRequirementId);
    },
    openLinked(linkedRequirementId: number) {
      this.$emit('openLinked', linkedRequirementId);
    },
    editDetails() {
      (this.$refs.details as any).openEdition();
      this.isEditing = true;
    },
    closeEditing() {
      const edited = findModifiedRequirement(
        this.$store.getters['requirementStorage/getRequirements'],
        this.openedRequirement,
      );
      if (edited.type !== this.openedRequirement.type) {
        this.loadTasks();
      }
      this.loadChangelog();
      this.$store.commit('requirementStorage/setOpenedDetailedViewRequirement', edited);
      this.editedTitle = edited.title;
      this.isEditing = false;
    },
    resetEditing() {
      this.closeEditing();
      this.editedTitle = this.openedRequirement.title;
    },
    openNewContainer(requirement: Requirement) {
      this.$emit('openMainrequirement', requirement.id);
    },
  },
});
