
























import User from '@/model/user';
import Requirement from '@/model/requirement';
import Vue from 'vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
export default Vue.extend({
  name: 'ModifyLeaders',
  components: {
    UserAvatar,
  },
  props: {
    requirement: Object as () => Requirement,
    users: Array as () => User[],
  },
  methods: {
    toggleLeader(user: User) {
      this.$emit('toggleLeader', user);
    },
    isAdded(user: User) {
      return this.requirement?.processOwnerEmails?.includes(user.email);
    },
  },
});
