import { i18n } from '@/i18n';

export const menus = [
  {
    name: i18n.t('dashboard.detailed-view.menu.tasks'),
    icon: '/images/list.svg',
    componentName: 'Tasks',
  },
  {
    name: i18n.t('dashboard.detailed-view.menu.attachments'),
    icon: '/images/attachments.svg',
    componentName: 'Attachments',
  },
  {
    name: i18n.t('dashboard.detailed-view.menu.comments'),
    icon: '/images/comment.svg',
    componentName: 'Comments',
  },
  {
    name: i18n.t('dashboard.detailed-view.menu.history'),
    icon: '/images/history.svg',
    componentName: 'Changelog',
  },
];
