



















































































import Vue from 'vue';
import Requirement from '@/model/requirement';
import Task from '@/model/task';
import TaskContainer from '@/components/Dashboard/DetailedView/Task/TaskContainer.vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import AssigneeSelection from '@/components/Dashboard/Table/TableColumns/Task/AssigneeSelection.vue';
import FileInput from '@/components/InputField/FileInput.vue';
import UploadedAttachment from '@/components/Dashboard/DetailedView/Attachment/UploadedAttachment.vue';
import { uploadDocument } from '@/helper/methods';

export default Vue.extend({
  name: 'DetailedTaskCard',
  components: {
    TaskContainer,
    DatePicker,
    AssigneeSelection,
    FileInput,
    UploadedAttachment,
  },
  props: {
    requirement: Object as () => Requirement,
    task: Object as () => Task,
    nextTask: Object as () => Task,
    isEditing: { type: Boolean, default: false },
    restricted: { type: Boolean, default: false },
  },
  data() {
    return {
      calendar: '/images/clock-calendar.svg',
      arrowIcon: '/images/arrow.svg',
      uploading: false,
    };
  },
  methods: {
    upload(attachments: File[]) {
      const formData = uploadDocument(attachments);
      if (formData.has('file')) {
        this.uploading = true;
        this.$store
          .dispatch('documentStorage/uploadTaskDocuments', {
            requirementId: this.requirement.id,
            taskId: this.task.id,
            formData,
          })
          .finally(() => (this.uploading = false));
      }
    },
  },
});
