



























import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import AdminRequirementTable from '@/components/Admin/Requirements/Table.vue';
import AdminPageButtons from '@/components/Admin/PageButtons.vue';
import PageOptions from '@/model/page/page-options';
import { RequirementTableType } from '@/model/enums/requirement-table-type';
import { mapGetters } from 'vuex';
import Menu from '@/model/menu';

export default Vue.extend({
  name: 'AdminRequirements',
  components: {
    LoadingIndicator,
    AdminRequirementTable,
    AdminPageButtons,
  },
  props: {
    selectedPage: { type: Object as () => Menu },
  },
  watch: {
    selectedPage() {
      this.page = this.selectedPage;
    },
  },
  data() {
    return {
      inactiveType: RequirementTableType.INACTIVE,
      deletedType: RequirementTableType.DELETED,
      initialLoading: true,
      inactivePageOptions: new PageOptions(0, 15),
      deletedPageOptions: new PageOptions(0, 15),
      page: this.selectedPage,
    };
  },
  mounted() {
    this.fetchInactive();
    this.fetchDeleted();
    this.$emit('setFilter', this.searchText);
    this.initialLoading = false;
  },
  computed: {
    ...mapGetters({
      inactive: 'adminRequirementStorage/getInactiveRequirements',
      deleted: 'adminRequirementStorage/getDeletedRequirements',
      searchText: 'filterStorage/getAdminRequirementsSearchText',
    }),
  },
  methods: {
    fetchInactive() {
      this.$store.dispatch('adminRequirementStorage/getInactivePageable', {
        pageOptions: this.inactivePageOptions,
        text: this.searchText,
      });
    },
    fetchDeleted() {
      this.$store.dispatch('adminRequirementStorage/getDeletedPageable', {
        pageOptions: this.deletedPageOptions,
        text: this.searchText,
      });
    },
    inactivePageoptionsChanged() {
      this.inactivePageOptions.page++;
      this.fetchInactive();
    },
    deletedPageoptionsChanged() {
      this.deletedPageOptions.page++;
      this.fetchDeleted();
    },
    textFiltering(text: string) {
      this.$store.commit('filterStorage/setAdminRequirementsSearchText', text);
      this.inactivePageOptions = new PageOptions();
      this.deletedPageOptions = new PageOptions();
      this.fetchInactive();
      this.fetchDeleted();
    },
  },
});
