













































import Vue from 'vue';
import Requirement from '@/model/requirement';
import RequirementDocument from '@/model/requirement-document';
import AdminRequirement from '@/model/admin-requirement';

export default Vue.extend({
  name: 'MainRequirementPanel',
  props: {
    requirements: Array as () => Requirement[] | RequirementDocument[] | AdminRequirement[],
    sectionNumber: { type: Number, default: null },
    panels: { type: Array as () => number[][], default: Array as () => number[][] },
    isDashboard: { type: Boolean, default: false },
  },
  data() {
    return {
      subPanels: new Array() as number[][],
    };
  },
  beforeMount() {
    this.subPanels = this.panels;
  },
  computed: {
    openedPanels: {
      get(): number[] {
        return this.subPanels[this.sectionNumber] ?? [];
      },
      set(value: number[]) {
        this.subPanels[this.sectionNumber] = value;
        this.$emit('changePanels', this.subPanels);
      },
    },
  },
  methods: {
    removedBorder(index: number): boolean {
      return this.requirements[index + 1] && !this.requirements[index + 1].single;
    },
    openMainDetailedView(requirementId: number) {
      this.$emit('openMainDetailedView', requirementId);
    },
  },
});
