























import Vue from 'vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { mapGetters } from 'vuex';
import Comment from '@/model/comment';
import UserDetails from '@/model/user-details';

export default Vue.extend({
  name: 'CommentRow',
  components: {
    UserAvatar,
  },
  props: {
    comment: Object as () => Comment,
    isDocumentContainer: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUserStorage/getCurrentUser' }),
  },
  methods: {
    isCurrentUser(user: UserDetails): boolean {
      return user.id === this.currentUser.id;
    },
  },
});
