






import Vue from 'vue';
import { State } from '@/model/enums/state';
import { Stateful } from '@/model/interfaces/stateful';

export default Vue.extend({
  name: 'Status',
  props: {
    stateful: Object as () => Stateful,
  },
  computed: {
    isExpired(): boolean {
      return this.stateful.state === State.EXPIRED;
    },
    isOk(): boolean {
      return this.stateful.state === State.OK;
    },
  },
});
