import { i18n } from '@/i18n';

export const adminPages = [
  {
    name: i18n.t('admin.page.users'),
    icon: 'fas fa-users',
    componentName: 'AdminUsers',
  },
  {
    name: i18n.t('admin.page.tasks'),
    icon: 'fas fa-tasks',
    componentName: 'AdminTypes',
  },
  {
    name: i18n.t('admin.page.requirements'),
    icon: 'fas fa-clipboard-list',
    componentName: 'AdminRequirements',
  },
  {
    name: i18n.t('admin.page.options'),
    icon: 'far fa-newspaper',
    componentName: 'BasicInformationOptions',
  },
];
