















import Vue from 'vue';
import Requirement from '@/model/requirement';
import Task from '@/model/task';
import TaskCompletionIcon from '@/components/Dashboard/Table/TableColumns/Task/TaskCompletionIcon.vue';

export default Vue.extend({
  name: 'TaskContainer',
  components: {
    TaskCompletionIcon,
  },
  props: {
    requirement: Object as () => Requirement,
    task: Object as () => Task,
    hasArrow: { type: Boolean, default: true },
    isEditing: { type: Boolean, default: false },
    restricted: { type: Boolean, default: false },
  },
});
