import { render, staticRenderFns } from "./AssigneeSelection.vue?vue&type=template&id=37ccb32a&scoped=true&"
import script from "./AssigneeSelection.vue?vue&type=script&lang=ts&"
export * from "./AssigneeSelection.vue?vue&type=script&lang=ts&"
import style0 from "./AssigneeSelection.vue?vue&type=style&index=0&id=37ccb32a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ccb32a",
  null
  
)

export default component.exports